/* ==== FOOTER ==== */
/* Footer style */
/* ------ */
/* FOOTER */
/* ------ */

ul {
    padding-inline-start: 0 !important;
}

footer {
    text-align: center;
    float: left;
    width: 100%;
}

footer #footer-top {
    color: #FFF;
    background: #0f151a;
    padding: 38px 0 26px;
}

footer #footer-top a {
    color: #FFF;
}

footer #footer-top .menu ul li {
    margin: 24px 0;
    text-align: center !important;
    list-style: none;
}

footer #footer-top .menu ul li a {
    font-size: 13px;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif !important;
}

footer #footer-top #ca-lang-selector {
    padding-left: 17px;
    height: 32px;
    width: 70px;
    margin: 0 auto;
    overflow: visible;
    color: #000;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/icon-language-footer.png) 0 center no-repeat;
}

footer #footer-top #ca-lang-selector .current {
    height: 32px;
    line-height: 32px;
    padding-right: 12px;
    font-size: 13px;
    font-weight: 600;
    color: #FFF;
    cursor: pointer;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/arrow-lang.png) right center no-repeat;
    white-space: nowrap;
}

footer #footer-top #ca-lang-selector ul.menu {
    left: 0;
    bottom: 32px;
    display: none;
}

footer #footer-top #ca-lang-selector ul.menu li {
    margin: 0 0 4px 0.5em;
}

footer #footer-top #ca-lang-selector ul.menu li a {
    height: 20px;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    color: #FFF;
    white-space: nowrap;
}

footer #footer-top #ca-lang-selector ul.menu.open-lang {
    display: block;
    background: #0f151a;
}

footer #footer-top .social {
    margin-top: 46px;
}

footer #footer-top .social .middle-social {
    margin: 12px auto 0;
    display: inline-block;
}

footer #footer-top .social span {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
}

footer #footer-top .social ul.menu li {
    float: left;
    margin-left: 10px;
}

footer #footer-top .social ul.menu li a {
    display: block;
    width: 28px;
    height: 28px;
    overflow: hidden;
    text-indent: -1000px;
}

footer #footer-top .social ul.menu li a.gp {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) 0 0 no-repeat;
    background-size: 300px 30px;
}

footer #footer-top .social ul.menu li a.yt {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) -60px 0 no-repeat;
    background-size: 300px 30px;
}

footer #footer-top .social ul.menu li a.fb {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) -120px 0 no-repeat;
    background-size: 300px 30px;
}

footer #footer-top .social ul.menu li a.ln {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) -180px 0 no-repeat;
    background-size: 300px 30px;
}

footer #footer-top .social ul.menu li a.tw {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) -240px 0 no-repeat;
}

footer #footer-top .social ul.menu li a.int {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas_mobile/images/footer/social-icons.svg) -300px 0 no-repeat;
    background-size: 358px 30px;
}

footer #footer-bottom {
    color: #617A87;
    background: #0b1014;
    padding: 20px 0 36px;
}

footer #footer-bottom .info {
    display: inline-flex;
}

footer #footer-bottom .info a {
    font-size: 11px;
    color: #617a87;
    margin-bottom: 36px;
}

footer #footer-bottom .info #footer-idblogo .logo-footer {
    display: block;
    width: 88px;
    height: 36px;
    margin: 0 auto;
    margin-bottom: 12px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 0 no-repeat;
}

footer #footer-bottom .supporters {
    padding-top: 20px;
    line-height: 30px;
}

footer #footer-bottom .supporters .row {
    margin: 7px 0;
}

footer #footer-bottom .supporters a {
    display: inline-block;
    height: 45px;
    background: #666;
    margin-left: 12px;
    text-indent: -100000px;
}

@media screen and (max-device-width: 320px) {
    footer #footer-bottom .supporters a {
        margin-left: 9px !important;
    }
}

footer #footer-bottom .supporters a.google {
    width: 58px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -176px no-repeat;
    background-size: 100px;
}

footer #footer-bottom .supporters a.sealand {
    width: 60px;
    height: 45px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -184px no-repeat;
    background-size: 70px;
}

footer #footer-bottom .supporters a.dhl {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -363px no-repeat;
    background-size: 100px;
}

footer #footer-bottom .supporters a.alibaba {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -455px no-repeat;
}

footer #footer-bottom .supporters a.facebook {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -638px no-repeat;
    background-size: 115px;
}

footer #footer-bottom .supporters a.master-card {
    width: 60px;
    height: 34px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -612px no-repeat;
    background-size: 95px;
}

body.front footer #footer-bottom {
    padding-bottom: 55px;
}

#footer {
    width: 100%;
    min-width: 900px;
    height: 128px;
    background: #0B1013;
    /* background: red; */
}

@media (max-width: 960px) {
    #footer {
        height: 180px;
    }
}

@media (max-width: 768px) {
    #footer {
        height: 284px;
    }
}

@media (orientation: landscape) {
    #footer {
        height: 128px !important;
    }
}

#footer.user-register-page-footer {
    height: 62px;
}

@media (max-width: 960px) {
    #footer.user-register-page-footer {
        height: 166px;
        border-top: #DDD 2px solid !important;
    }
}

#footer #footer-top {
    height: 60px;
    padding-top: 15px;
    font-size: 11px;
    color: #fff;
    background: #0F161A;
}

#footer #footer-top a {
    text-decoration: none;
}

#footer #footer-top #block-menu-footer-menu {
    float: left;
    margin-left: 20px;
}

#footer #footer-top #block-menu-footer-menu ul.menu {
    overflow: hidden;
}

#footer #footer-top #block-menu-footer-menu ul.menu li {
    float: left;
    margin-right: 20px;
}

#footer #footer-top #block-menu-footer-menu ul.menu li a {
    display: block;
    line-height: 30px;
    font-size: 11px;
    color: #fff;
}

#footer #footer-top #block-menu-footer-menu ul.menu li a:hover {
    text-decoration: none;
}

#footer #footer-top #ca-lang-selector {
    float: left;
    position: relative;
    padding-left: 17px;
    height: 32px;
    overflow: visible;
    color: #000;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/icon-language-footer.png) 0 center no-repeat;
}

#footer #footer-top #ca-lang-selector .current {
    height: 32px;
    line-height: 32px;
    padding-right: 12px;
    font-size: 11px;
    color: #FFF;
    cursor: pointer;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/arrow-lang.png) right center no-repeat;
    white-space: nowrap;
}

#footer #footer-top #ca-lang-selector ul.menu {
    position: absolute;
    left: 0;
    bottom: 32px;
    display: none;
    padding: 3px 0;
    background: #111C21;
}

#footer #footer-top #ca-lang-selector ul.menu li a {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    font-size: 11px;
    text-decoration: none;
    color: #FFF;
    white-space: nowrap;
}

#footer #footer-top #ca-lang-selector:hover ul.menu {
    display: block;
}

#footer #footer-top #social-networks {
    float: right;
    margin-right: 20px;
}

#footer #footer-top #social-networks .block-title {
    float: left;
    margin-right: 10px;
    line-height: 28px;
}

#footer #footer-top #social-networks .block-title h2 {
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase;
    color: #ADB8BF;
    text-align: center;
}

#footer #footer-top #social-networks .block-content {
    float: left;
}

#footer #footer-top #social-networks .block-content ul.menu {
    overflow: hidden;
}

#footer #footer-top #social-networks .block-content ul.menu li {
    float: left;
    margin-left: 10px;
}

#footer #footer-top #social-networks .block-content ul.menu li a {
    display: block;
    width: 28px;
    height: 28px;
    overflow: hidden;
    text-indent: -1000px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.gp {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) 0 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.gp:hover {
    background-position: -30px 0;
}

#footer #footer-top #social-networks .block-content ul.menu li a.yt {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) -60px 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.yt:hover {
    background-position: -90px 0;
}

#footer #footer-top #social-networks .block-content ul.menu li a.fb {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) -120px 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.fb:hover {
    background-position: -150px 0;
}

#footer #footer-top #social-networks .block-content ul.menu li a.ln {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) -180px 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.ln:hover {
    background-position: -210px 0;
}

#footer #footer-top #social-networks .block-content ul.menu li a.tw {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) -240px 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.tw:hover {
    background-position: -270px 0;
}

#footer #footer-top #social-networks .block-content ul.menu li a.int {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/social-icons.svg) -300px 0 no-repeat;
    background-size: 358px 30px;
}

#footer #footer-top #social-networks .block-content ul.menu li a.int:hover {
    background-position: -330px 0;
}

#footer #footer-bottom {
    height: auto;
    padding-top: 15px;
    font-size: 11px;
    color: #798A94;
    background: #0B1013;
}

#footer #footer-bottom #footer-info {
    background: #0B1013;
    float: left;
    width: 50%;
    height: 62px;
    padding-left: 20px;
    padding-bottom: 20px;
    line-height: 32px;
}

#footer #footer-bottom #footer-info .block-content {
    height: 32px;
    padding-left: 98px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 0 no-repeat;
}

#footer #footer-bottom #footer-info .block-content.lang-pt {
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 -100px no-repeat;
}

#footer #footer-bottom #footer-info .block-content.lang-es {
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 -100px no-repeat;
}

#footer #footer-bottom #footer-info #footer-idblogo {
    display: block;
    color: #798A94;
}

#footer #footer-bottom #footer-info p {
    line-height: 15px;
}

#footer #footer-bottom #footer-supporters {
    background: #0B1013;
    float: right;
    width: 50%;
    height: 62px;
    padding-right: 20px;
    line-height: 30px;
    /*
      img {
        vertical-align: middle;
        margin-left: 15px;
      }
      */
}

#footer #footer-bottom #footer-supporters .block-content {
    float: right;
    white-space: nowrap;
    overflow: hidden;
}

#footer #footer-bottom #footer-supporters span {
    padding-right: 5px;
    vertical-align: text-top;
}

#footer #footer-bottom #footer-supporters a {
    display: inline-block;
    height: 30px;
    background: #666;
    margin-left: 15px;
    text-indent: -100000px;
}

#footer #footer-bottom #footer-supporters a.google {
    width: 58px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -159px no-repeat;
    background-size: 90px;
}

#footer #footer-bottom #footer-supporters a.sealand {
    width: 62px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -206px no-repeat;
    background-size: 78px;
    height: 45px;
}

#footer #footer-bottom #footer-supporters a.dhl {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -400px no-repeat;
    background-size: 109px;
}

#footer #footer-bottom #footer-supporters a.alibaba {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v4.svg) 0 -463px no-repeat;
}

#footer #footer-bottom #footer-supporters a.facebook {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -614px no-repeat;
    background-size: 111px;
}

#footer #footer-bottom #footer-supporters a.master-card {
    width: 81px;
    height: 45px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -713px no-repeat;
    background-size: 111px;
}

#footer #footer-bottom #footer-supporters a.idb {
    width: 100px;
    height: 45px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 12px no-repeat;
    background-size: 75px;
    margin-left: 10px;
    margin-right: 5px;
}

#footer-new-home {
    bottom: 0;
    width: 100%;
    min-width: 900px;
    height: 128px;
    background: #0B1013;
}

@media screen and (max-width: 1240px) {
    #footer-new-home {
        min-width: auto;
    }
}

#footer-new-home.user-register-page-footer {
    height: 62px;
}

@media (max-width: 960px) {
    #footer-new-home.user-register-page-footer {
        height: 166px;
        border-top: #DDD 2px solid !important;
    }
}

#footer-new-home #footer-top {
    height: 800px;
    font-size: 11px;
    color: #fff;
    background: #062939;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-top {
        height: 1300px;
    }
}

@media screen and (max-width: 600px) {
    #footer-new-home #footer-top {
        height: 1200px;
    }
}

#footer-new-home #footer-top .image {
    width: 100%;
    height: auto;
}

#footer-new-home #footer-top .image img {
    display: block;
    height: auto;
    width: 100%;
}

#footer-new-home #footer-top a {
    text-decoration: none;
}

#footer-new-home #footer-top .footer-columns {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
}

#footer-new-home #footer-top .footer-columns .col-div-10 {
    width: 100%;
    height: auto;
    margin: 110px 0 0;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-top .footer-columns .col-div-10 {
        width: 86%;
        margin: 110px 0 0 30px;
    }
}

#footer-new-home #footer-top .footer-columns .col-div-5 {
    width: 20%;
    height: auto;
    margin: 40px 0 0;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-top .footer-columns .col-div-5 {
        width: 86%;
        margin: 40px 0 0 30px;
    }
}

#footer-new-home #footer-top .footer-columns .col-div-5 .block-title {
    float: left;
    width: 100%;
}

#footer-new-home #footer-top .footer-columns .col-div-5 .block-title h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #d8d8d8;
    width: 100%;
}

#footer-new-home #footer-top .footer-columns ul.menu {
    overflow: hidden;
    float: left;
    margin-top: 5px;
}

#footer-new-home #footer-top .footer-columns ul.menu li {
    float: left;
    width: 100%;
}

#footer-new-home #footer-top .footer-columns ul.menu li a {
    display: block;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #d8d8d8;
    text-decoration: none;
    white-space: nowrap;
}

#footer-new-home #footer-top .footer-columns ul.menu li a:hover {
    text-decoration: none;
    color: #fff;
}

#footer-new-home #footer-top #ca-lang-selector {
    float: left;
    position: relative;
    height: auto;
    overflow: visible;
    color: #000;
    margin-top: 5px;
}

#footer-new-home #footer-top #ca-lang-selector .current {
    height: 35px;
    color: #FFF;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #d8d8d8;
}

#footer-new-home #footer-top #ca-lang-selector ul.menu li a {
    display: block;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #d8d8d8;
    text-decoration: none;
    white-space: nowrap;
}

#footer-new-home #footer-top #ca-lang-selector:hover ul.menu {
    display: block;
}

#footer-new-home #footer-top #social-networks {
    float: left !important;
}

#footer-new-home #footer-top #social-networks .block-content {
    float: left;
}

#footer-new-home #footer-top #social-networks .block-content ul.menu {
    overflow: hidden;
}

#footer-new-home #footer-top #social-networks .block-content ul.menu li {
    float: left;
}

#footer-new-home #footer-top #social-networks .block-content ul.menu li a {
    display: block;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #d8d8d8;
    text-decoration: none;
    white-space: nowrap;
}

#footer-new-home #footer-bottom {
    height: auto;
    padding-top: 15px;
    font-size: 11px;
    color: #798A94;
    background: #0B1013;
}

#footer-new-home #footer-bottom .home-footer {
    padding-bottom: 200px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom {
        height: 150px;
        margin-bottom: 100px;
    }
}

#footer-new-home #footer-bottom #footer-info {
    background: #0B1013;
    float: left;
    width: 50%;
    height: 62px;
    padding-left: 20px;
    padding-bottom: 20px;
    line-height: 32px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-info {
        width: 100%;
    }
}

#footer-new-home #footer-bottom #footer-info .block-content {
    height: 32px;
}

#footer-new-home #footer-bottom #footer-info #footer-idblogo {
    display: block;
    color: #798A94;
}

#footer-new-home #footer-bottom #footer-info p {
    line-height: 15px;
}

#footer-new-home #footer-bottom #footer-supporters {
    background: #0B1013;
    float: right;
    width: 50%;
    height: 62px;
    padding-right: 20px;
    line-height: 30px;
    /*
      img {
        vertical-align: middle;
        margin-left: 15px;
      }
      */
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters {
        position: relative;
    }
}

#footer-new-home #footer-bottom #footer-supporters .block-content {
    float: left;
    white-space: nowrap;
    overflow: hidden;
}

#footer-new-home #footer-bottom #footer-supporters span {
    padding-right: 5px;
    vertical-align: text-top;
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters span {
        font-size: 9px;
        position: absolute;
        top: -11px;
        left: 21px;
    }

    #footer-new-home #footer-bottom #footer-supporters span.created {
        left: auto;
        right: 54px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a {
    display: inline-block;
    height: 35px;
    background: #666;
    margin-left: 15px;
    text-indent: -100000px;
}

#footer-new-home #footer-bottom #footer-supporters a.google {
    width: 58px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -159px no-repeat;
    background-size: 90px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.google {
        width: 48px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -144px no-repeat;
        background-size: 81px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.google {
        width: 35px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -95px no-repeat;
        background-size: 59px;
        margin-left: 10px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a.sealand {
    width: 62px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -206px no-repeat;
    background-size: 78px;
    height: 45px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.sealand {
        width: 50px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -196px no-repeat;
        background-size: 73px;
        height: 40px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.sealand {
        width: 47px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -150px no-repeat;
        background-size: 59px;
        height: 40px;
        margin-left: 10px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a.dhl {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -400px no-repeat;
    background-size: 109px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.dhl {
        width: 72px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -370px no-repeat;
        background-size: 101px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.dhl {
        width: 50px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -290px no-repeat;
        background-size: 82px;
        margin-left: 10px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a.alibaba {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v4.svg) 0 -463px no-repeat;
}

#footer-new-home #footer-bottom #footer-supporters a.facebook {
    width: 81px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/ca_home/metagray.png) 0 15px no-repeat;
    background-size: 80px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.facebook {
        width: 70px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/ca_home/metagray.png) 0 15px no-repeat;
        background-size: 69px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.facebook {
        width: 54px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/ca_home/metagray.png) 0 20px no-repeat;
        background-size: 48px;
        margin-left: 10px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a.master-card {
    width: 81px;
    height: 45px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -713px no-repeat;
    background-size: 111px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.master-card {
        width: 42px;
        height: 45px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -567px no-repeat;
        background-size: 89px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.master-card {
        width: 32px;
        height: 45px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5.svg) 0 -464px no-repeat;
        background-size: 74px;
        margin-left: 10px;
        margin-right: 5px;
    }
}

#footer-new-home #footer-bottom #footer-supporters a.idb {
    width: 100px;
    height: 45px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 5px no-repeat;
    background-size: 105px;
}

@media screen and (max-width: 992px) {
    #footer-new-home #footer-bottom #footer-supporters a.idb {
        width: 112px;
        height: 45px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 3px no-repeat;
        background-size: 105px;
    }
}

@media screen and (max-width: 420px) {
    #footer-new-home #footer-bottom #footer-supporters a.idb {
        width: 100px;
        height: 45px;
        background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v-2.svg) 0 12px no-repeat;
        background-size: 75px;
        margin-left: 10px;
        margin-right: 5px;
    }
}

.front #footer-new-home {
    bottom: initial !important;
}

/* Exclusive for User Registration pages */
#footer.user-register-page-footer {
    position: absolute;
    border-top: #DDD 1px solid;
    background: #F0F0F0;
    bottom: 0;
    height: 68px;
}

#footer.user-register-page-footer #footer-bottom {
    color: #999;
    background: #F0F0F0;
}

#footer.user-register-page-footer #footer-bottom #footer-info {
    background: #F0F0F0;
}

#footer.user-register-page-footer #footer-bottom #footer-info .block-content {
    background-image: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5-gray.svg);
}

#footer.user-register-page-footer #footer-bottom #footer-info .block-content.lang-pt,
#footer.user-register-page-footer #footer-bottom #footer-info .block-content.lang-es {
    background-image: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5-gray.svg);
    background-position: 0 -93px;
}

#footer.user-register-page-footer #footer-bottom #footer-info #footer-idblogo {
    color: #999;
}

#footer.user-register-page-footer #footer-bottom #footer-supporters {
    background: #F0F0F0;
}

#footer.user-register-page-footer #footer-bottom #footer-supporters .google,
#footer.user-register-page-footer #footer-bottom #footer-supporters .sealand,
#footer.user-register-page-footer #footer-bottom #footer-supporters .dhl,
#footer.user-register-page-footer #footer-bottom #footer-supporters .facebook,
#footer.user-register-page-footer #footer-bottom #footer-supporters .master-card {
    background-image: url(https://connectamericas.com/sites/all/themes/connectamericas/images/footer/footer-icons-v5-gray.svg);
}

/* End Footer style */
body.admin-menu #admin-menu {
    z-index: 11000;
}
