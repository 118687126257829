#main #header #header-top #logo {
    display: block;
    float: left;
    width: 160px;
    height: 70px;
    text-indent: -1000px;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/logo.svg) 17px 7px no-repeat;
}

#main #header #header-top #logo:hover {
    background-color: #00A3B7;
}

#main #header #header-top #header-search-form {
    position: absolute;
    top: 9px;
    width: 100%;
    height: 32px;
    z-index: -1;
}

#main #header #header-top #header-search-form form {
    display: table;
    width: 40%;
    min-width: 450px;
    height: 32px;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
}

#main #header #header-top #header-search-form form input.form-text {
    font-family: "Open Sans", Arial, sans-serif;
}

#main #header #header-top #header-search-form form input.form-text.error {
    color: #1F2122;
}

#main #header #header-top #header-search-form form>div {
    overflow: visible;
    display: table-row;
    width: 100%;
    height: 32px;
}

#main #header #header-top #header-search-form .selector {
    display: table-cell;
    position: relative;
    vertical-align: top;
    width: 32px;
    height: 32px;
    line-height: 32px;
    overflow: visible;
}

#main #header #header-top #header-search-form .selector .current {
    display: block;
    padding: 0 30px 0 10px;
    font-size: 12px;
    color: #666;
    background: #FFF url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-arrow.svg) right -10px top 14px no-repeat;
    background-size: 30px 80px;
    cursor: pointer;
    white-space: nowrap;
}

#main #header #header-top #header-search-form .selector ul.menu {
    display: none;
    position: absolute;
    top: 32px;
    right: 0;
    width: auto;
    min-width: 120px;
    background: #FFF;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

#main #header #header-top #header-search-form .selector ul.menu.open {
    display: block;
}

#main #header #header-top #header-search-form .selector ul.menu li a {
    display: block;
    padding: 0 15px 0 10px;
    line-height: 30px;
    font-size: 12px;
    text-align: right;
    color: #1F2122;
    white-space: nowrap;
}

#main #header #header-top #header-search-form .selector ul.menu li a:hover {
    background: #F0F0F0;
}

#main #header #header-top #header-search-form .form-item-criteria-header {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    height: 32px;
}

#main #header #header-top #header-search-form .form-item-criteria-header .form-text {
    width: 100%;
    height: 25px;
    padding: 0 10px;
    font-size: 12px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: #CCC 1px solid;
    margin-top: 4px;
}

#main #header #header-top #header-search-form .form-submit {
    display: table-cell;
    vertical-align: top;
    width: 32px;
    height: 32px;
    border: 0;
    text-indent: -1000px;
    background: #007E8E url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/header-icons-v.svg) 8px 8px no-repeat;
    background-size: 30px 180px;
    cursor: pointer;
}

#main #header #header-top #header-search-form .form-submit:hover {
    background: #006572 url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/header-icons-v.svg) 8px 8px no-repeat;
}

#main #header #header-bottom {
    overflow: visible;
    height: 40px;
    background: #007E8E;
}

#main #header #header-bottom #block-menu-header-menu {
    margin-left: 15px;
}

#main #header #header-bottom #block-menu-header-menu .block-content {
    /* First level*/
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu {
    overflow: visible;
    height: 40px;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li {
    position: relative;
    float: left;
    height: 40px;
    margin-left: 10px;
    /* Second level */
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>a {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 35px 0 10px;
    font-size: 13px;
    text-transform: uppercase;
    color: #FFF;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-arrow.svg) 105% -22px no-repeat;
    background-size: 30px 80px;
    white-space: nowrap;
    padding-left: 15px;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>a:hover {
    background-color: #006572;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.first {
    margin-left: 0;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.first a {
    padding-left: 36px;
    padding-right: 15px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/header-icons-v.svg) 10px -77px no-repeat;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.first a:hover {
    background-color: #006572;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.last {
    float: right;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.last a {
    font-size: 12px;
    text-transform: none !important;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.last>ul {
    right: 10px !important;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    padding-top: 10px;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-top-arrow.png) 15px 3px no-repeat;
    /* Third level and more */
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li {
    position: relative;
    border-bottom: #0E4B52 1px solid;
    /* Open next level */
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li a {
    display: block;
    height: 39px;
    line-height: 39px;
    padding: 0 15px;
    font-size: 13px;
    text-transform: none !important;
    color: #FFF;
    background: #014149;
    white-space: nowrap;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li a:hover {
    background: #01343A;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li.last {
    border-bottom: 0;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li:hover>a {
    background: #01343A;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu li:hover>ul.menu {
    display: block;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu ul.menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    padding-left: 10px;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-left-arrow.png) 3px 15px no-repeat;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li>ul.menu>li>a {
    text-transform: none !important;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li:hover>a {
    background-color: #006572;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li:hover>ul.menu {
    display: block;
}

#main #header #header-bottom #block-menu-header-menu .block-content>ul.menu>li.last>ul.menu {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-top-arrow.png) 90% 3px no-repeat;
    right: 0;
    left: auto;
}

#main #header #header-bottom #block-menu-header-menu .block-content ul.menu li.last ul.menu li a {
    height: 35px;
    line-height: 35px;
    font-size: 12px !important;
}

#main #header #header-bottom #block-menu-header-help-menu {
    float: right;
    height: 40px;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu {
    overflow: visible;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li {
    position: relative;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>a {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 12px;
    color: #FFF;
    white-space: nowrap;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>a:hover {
    background-color: #006572;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>ul {
    display: none;
    position: absolute;
    top: 40px;
    right: 10px;
    padding-top: 10px;
    background: transparent url(https://connectamericas.com/sites/all/themes/connectamericas/images/header/menu-top-arrow.png) 90% 3px no-repeat;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>ul>li {
    border-bottom: #0E4B52 1px solid;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>ul>li.last {
    border-bottom: 0;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>ul>li>a {
    display: block;
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    font-size: 12px;
    color: #FFF;
    background: #014149;
    white-space: nowrap;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li>ul>li>a:hover {
    background: #01343A;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li:hover>a {
    background: #006572;
}

#main #header #header-bottom #block-menu-header-help-menu .block-content>ul.menu>li:hover>ul {
    display: block;
}

#main .user-register-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 42px;
    line-height: 52px;
    color: #fff;
    text-align: center;
    margin: 10px auto 30px;
    width: 100%;
    max-width: 1200px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    z-index: 5;
    position: relative;
}

@media (max-width: 992px) {
    #main .user-register-title {
        font-size: 35px;
        line-height: 43px;
        width: 80%;
    }
}

@media (max-width: 400px) {
    #main .user-register-title {
        font-size: 30px;
        line-height: 36px;
        width: 80%;
    }
}

#main #header.user-register-page-footer {
    height: 94px;
    background: transparent;
    border-bottom: 0;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

#main #header.user-register-page-footer #logo {
    display: block;
    float: left;
    width: 152px;
    height: 94px;
    margin: 20px 0 0 15px;
    text-indent: -1000px;
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/ca_header_menu/logo_home_ca.svg) top center no-repeat;
}

#main #header.user-register-page-footer .login {
    float: right;
    height: 94px;
    line-height: 60px;
    margin-right: 20px;
    font-size: 13px;
}

@media (max-width: 992px) {
    #main #header.user-register-page-footer .login {
        float: left;
        width: 100%;
        margin: 0 auto 0;
        font-size: 13px;
    }
}

#main #header.user-register-page-footer .login a.btn {
    background: transparent;
    transition: background-color 0.2s;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #ffffff;
    border-radius: 19px;
    margin: 25px 25px 0 0;
    color: #ffffff !important;
    padding: 0 20px;
}

@media (max-width: 992px) {
    #main #header.user-register-page-footer .login a.btn {
        margin: 0px auto;
        display: table;
    }
}

#main #header.user-register-page-footer .login a.btn:hover {
    border: 1px solid #ffffff;
    color: #00B5CC !important;
    background-color: #ffffff !important;
}

#main #content-wrapper.user-register-page-footer .messages.error {
    background-color: #fdf2b8;
    position: static;
    padding: 10px 10px 10px 32px;
}

#main #content-wrapper {
    min-height: 430px;
}

#main #content-wrapper .breadcrumbcontainer {
    background-color: #FFF;
    width: 100%;
    border-bottom: 1px dashed #dcdcdc;
}

#main #content-wrapper .breadcrumbcontainer .breadcrumb {
    font-size: 12px;
    color: #1f2122;
    line-height: 20px;
    padding: 12px 20px;
    margin: auto;
}

#main #content-wrapper .breadcrumbcontainer .breadcrumb .bc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#main #content-wrapper .breadcrumbcontainer .breadcrumb a {
    color: #00b5cc;
}

#main #content-wrapper .breadcrumbcontainer .breadcrumb a:hover {
    text-decoration: underline;
}

#main #content-wrapper .messages {
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    margin: 30px auto;
    padding: 0;
    border: none;
    background: #DBF7B4;
    color: #1F2122;
    word-break: break-word;
    -ms-word-break: break-all;
    z-index: 10;
    position: relative;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

#main #content-wrapper .messages .icon {
    display: block;
    float: left;
    width: 50px;
    height: 50px;
    background: #7CAB38 url(https://connectamericas.com/sites/all/themes/connectamericas/images/profile/IconSuccess.svg) center center no-repeat;
    background-size: 24px 16px;
}

#main #content-wrapper .messages.warning {
    background: #FDE9BB;
}

#main #content-wrapper .messages.warning .icon {
    background: #F8BC34 url(https://connectamericas.com/sites/all/themes/connectamericas/images/profile/IconAlert.svg) center center no-repeat;
    background-size: 3px 20px;
}

#main #content-wrapper .messages.error {
    background: #FECDCD;
}

#main #content-wrapper .messages.error .icon {
    background: #F10F0F url(https://connectamericas.com/sites/all/themes/connectamericas/images/profile/IconError.svg) center center no-repeat;
    background-size: 19px 19px;
}

#main #content-wrapper .messages .message-content {
    float: left;
    padding: 15px;
}

#main #content-wrapper #content {
    position: relative;
    background: #FFF !important;
}

#main #content-wrapper #content .block .block-title,
#main #content-wrapper #content .block .block-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

#main #content-wrapper #content .block .block-content.full {
    max-width: 100%;
}

#main.mainregister {
    background: url(https://connectamericas.com/sites/all/themes/connectamericas/images/hero/slider1.png) no-repeat center top !important;
    background-size: cover !important;
    min-height: 100vh;
}

#main.mainregister .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: transparent linear-gradient(244deg, #FA7716 0%, #3A00A8 100%) 0% 0% no-repeat padding-box;
    opacity: 0.77;
    z-index: 0;
}

#main .terms {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
}

#main .terms p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    text-align: center;
    width: 100%;
    z-index: 5;
    position: relative;
    margin: 40px auto;
}

#main .terms p a {
    color: #fff;
}

.gigya-screen *,
div.gigya-screen,
.gigya-screen span,
.gigya-screen a:hover,
.gigya-screen a:visited,
.gigya-screen a:link,
.gigya-screen a:active {
    border: none;
    padding: 0px;
    margin: 0px;
    /*    color: inherit; */
    text-decoration: none;
    width: auto;
    float: none;
    -moz-border-radius: 0;
    border-radius: 0;
    /*    font-family: arial; */
    /*    font-size: 12px; */
    /*    color: #333333; */
    background: none;
    text-align: left;
    /*    font-family: arial; */
    box-sizing: content-box;
}

.gigya-screen a,
.gigya-screen a:visited,
.gigya-screen a:link,
.gigya-screen a:active {
    color: #0098ca;
    text-decoration: underline;
}

.gigya-screen-set .gigya-screen {
    margin: 0 auto;
}

/*
.gigya-screen h2 {
    font-size:16px;
    color:#333;
    font-weight: normal;
}*/

.gigya-screen a:hover {
    text-decoration: none;
}

.gigya-screen h1 {
    /*    border-bottom: 0.625em none;*/
    /*    border-bottom: 1px solid #dbdbdb; */
    /*    font-size: 16px; */
    /*    font-family: Arial; */
    /*    font-weight: bold; */
    /*    color: #333; */
    /*    padding-bottom: 5px; */
    /*    margin-bottom: 45px; */
    /*    color: #1384C6;
        font-size: 1.5em;
        font-family:Montserrat-Regular,Helvetica,Arial,sans-serif; */
    padding: 1em 0em 0.625em 1em;
}

.gigya-screen hr {
    margin-bottom: 0.625em;
    background-color: #4C4C4C;
    /*border-style:solid;
    border-width: 0.1875em;*/
}

.gigya-screen .gigya-layout-row {
    width: 100%;
    zoom: 1;
    float: left;
    display: block;
}

.gigya-screen .gigya-layout-cell {
    float: left;
    width: 50%;
    margin-top: 1px;
}

.gigya-screen .gigya-layout-cell-right {
    float: right;
    width: 50%;
    margin-top: 1px;
}

.gigya-screen .gigya-layout-cell-3 {
    width: 33%;
    float: left;
    /* LTR */
    margin-top: 1px;
}

.gigya-screen .gigya-layout-cell-right-3 {
    width: 34%;
    float: right;
    /* LTR */
    margin-top: 1px;
}

.gigya-screen select {
    margin: 5px 0px;

    /*padding: 5px;*/
    /*border-radius: 5px;*/
    background-color: #FFFFFF;
    border: 2px solid #CCCCCC;
    /*-moz-box-sizing: border-box;*/
    /*-webkit-box-sizing: border-box;*/
    box-sizing: border-box;
    width: 100%;
}

.gigya-screen option {
    padding: 5px;
}

.gigya-screen option:hover {
    background-color: #3593C1;
}

.gigya-screen .gigya-label {
    display: block;
    /*font-weight: bold;*/
    /*font-size: 12px;*/
    font-size: 1em;
    font-family: OpenSans-Semibold, Helvetica, Arial, sans-serif;
    color: #4c4c4c;
}

.gigya-label-text {
    padding-bottom: 5px !important;
}

.gigya-label-text-info {
    font-size: 0.6875em;
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
}

.gigya-composite-control-checkbox .gigya-label-text {
    font-size: 0.875em;
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
}

.gigya-input-text,
.gigya-input-password,
.gigya-textarea {
    font-size: 14px;
    background: #ffffff !important;
    border: 2px solid #cccccc;
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
    height: 25px;
    line-height: 25px;
    margin: 0 0 10px 0;
    padding: 5px 0px 5px 0px;
    width: 100%;
    color: #1f2122;
    margin-bottom: 5px;
}

.gigya-textarea {
    padding: 5px;
}

.gigya-input-text,
.gigya-input-password,
.gigya-input-submit,
.gigya-input-button {
    height: 20px;
}

.gigya-screen select {
    height: 33px;
}

.gigya-input-text:focus,
.gigya-input-password:focus,
.gigya-textarea:focus,
.gigya-screen select:focus {
    border: 1px solid #87B9EA;
    outline: none;
}

.gigya-input-text:focus,
.gigya-input-password:focus,
.gigya-textarea:focus {
    box-shadow: 0 0 3px #50BADE, inset 0 1px 3px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 0 3px #50BADE, inset 0 1px 3px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 0 3px #50BADE, inset 0 1px 3px rgba(0, 0, 0, .05);
}

.gigya-input-submit,
.gigya-input-button {
    text-align: center;
    border: 1px solid #CECECE;
    cursor: pointer;
    padding: 0 12px;
    text-shadow: none !important;
    /*    color: #333333; */
    font-weight: normal !important;
    border-radius: 4px;
    /*line-height: 26px;*/
    background: #F2F2F2;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFF", endColorstr="#F2F2F2");
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F2F2F2));
    background: -moz-linear-gradient(top, #FFFFFF, #F2F2F2);
    height: 2.5em;
    background-color: #1384c6;
    font-family: Montserrat-Regular, Helvetica, Arial, sans-serif;
    min-width: 110px;
}

.gigya-input-submit:hover,
.gigya-input-button:hover {
    background: #FFFFFF;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F2F2F2", endColorstr="#FFFFFF");
    background: -webkit-gradient(linear, left top, left bottom, from(#F2F2F2), to(#FFFFFF));
    background: -moz-linear-gradient(top, #F2F2F2, #FFFFFF);
}

.gigya-input-radio,
.gigya-input-checkbox {
    margin-right: 5px;
    height: 14px;
    width: 14px;
    float: left;
    margin-top: 4px;
    vertical-align: middle;
}

input.gigya-input-text,
input.gigya-input-password {
    display: block;
}

.gigya-screen .gigya-composite-control {
    padding: 5px 13px 5px 0px;
}

.gigya-screen .gigya-composite-control-submit,
.gigya-screen .gigya-composite-control-button,
.gigya-screen .gigya-composite-control-form-error {
    padding-bottom: 5px;
    padding-right: 13px;
}

.gigya-composite-control-multi-choice .gigya-multi-choice-item,
.gigya-composite-control-checkboxes .gigya-checkboxes-item {
    padding-top: 5px;
}

.gigya-composite-control-form-error {
    padding-bottom: 5px;
    padding-right: 5px;
}

.gigya-screen .gigya-composite-control-submit {
    text-align: right;
}

.gigya-screen .gigya-composite-control-label {
    display: block;
}

.gigya-clear {
    clear: both;
    overflow: hidden;
    font-size: 0px;
}

.gigya-composite-control-radio label {
    font-weight: normal;
}

.gigya-composite-control-checkbox label {
    font-weight: normal;
}

.gigya-screen .gigya-composite-control-checkbox {
    padding: 5px 10px;
}

.gigya-screen .gigya-composite-control-checkbox label {
    display: inline;
    text-indent: 10px;
    margin-left: 0px;
}

.gigya-screen .gigya-message {
    font-size: 14px;
    color: #333;
    display: block;
    text-align: center;
}

.gigya-screen .gigya-error-display {
    display: block;
    visibility: hidden;
}

.gigya-screen .gigya-error-display-active {
    display: block;
    visibility: visible;
}

.gigya-screen .gigya-error-msg {
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
    font-size: 13px !important;
    /*background-color: #db1e04;*/
    /*line-height: 14px;*/
    color: #db1e04 !important;
    display: block;
    font-weight: normal;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*padding: 5px 10px;*/
    line-height: 22px;

    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
}

.gigya-screen .gigya-form-error-msg {
    _height: 26px;
    min-height: 26px;
    line-height: 14px;
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
    background-color: #db1e04;
    color: #FFFFFF !important;
    font-size: 13px !important;
    text-align: center;
    margin-top: 1px;
    border: 1px solid #FFDCCE;
    display: block;
    font-weight: normal;
    padding: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.gigya-screen a.gigya-composite-control-link {
    display: block;
    padding-left: 13px;
}

.gigya-screen .gigya-composite-control a.gigya-composite-control-link {
    display: inline;
    padding: 0px;
}

.gigya-screen .gigya-composite-control-checkbox .gigya-label,
.gigya-screen .gigya-composite-control-radio .gigya-label {
    font-weight: normal;
}

.gigya-layout-footer {
    text-align: right;
    clear: both;
}

.gigya-spacer-footer {
    height: 1.875em;
}

#gigya-login-screen .gigya-composite-control-social-login,
#gigya-register-screen .gigya-composite-control-social-login {
    /*border-right: 1px solid #CCC;*/
    height: 125px;
    width: 285px;
    padding: 13px 38px 0 0px;
}

/*#gigya-register-screen .gigya-composite-control-social-login {
    height: 240px;
}*/
.gigya-required-display {
    color: #38b6ff;
    font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: normal;
    display: inline-block;
    padding: 0 5px;
}

#gigya-login-screen a.forgotPassword,
#gigya-login-screen a.forgotPassword:link {
    float: right;
    font-weight: normal;
    text-decoration: none;
}

#gigya-login-screen a.forgotPassword:hover,
#gigya-login-screen a.forgotPassword:active {
    text-decoration: underline;
}

.gigya-checkboxes-item label,
.gigya-multi-choice-item label {
    font-weight: normal;
    float: left;
}

div.gigya-screen#gigya-complete-registration-screen {
    height: 31em;
    width: 750px !important;
}

div.gigya-profile-photo {
    border-radius: 3px;
    min-width: 64px;
    min-height: 64px;
    _width: 64px;
    _height: 64px;
}

div.gigya-screen-dimmer {
    position: absolute;
    opacity: 0.5;
    filter: 'alpha(opacity=50)';
    background-color: #FFFFFF;
}

div.gigya-screen-loader {
    position: absolute;
    background: transparent url('http://cdn.gigya.com/gs/i/accounts/bigLoader.gif') no-repeat center center;
}

div.gigya-screen-dialog {
    position: absolute;
    display: inline-block;
    top: 25px;
    left: 25px;
    border: none !important;
    border-radius: 3px;
    background-color: #FFFFFF;
}

div.gigya-screen-dialog * {
    box-sizing: content-box;
}

div.gigya-screen-dialog-content {
    overflow: auto;
    /*padding: 25px;*/
    /*width: 700px !important;*/
    /*height: 350px !important;*/
    padding: 0 0;
    box-sizing: content-box;
}

.gigya-footer-bar {
    position: absolute;
    bottom: 0.625em;
    /*left:0;*/
    width: 90%;
    /*zoom:1;*/
    /*float:left;*/
    /*display:block;*/
    /*padding-bottom: 30px;*/
}

.gigya-footer-cell {
    float: left;
    width: 50%;
}

.gigya-footer-cell-right {
    float: right;
    width: auto;
    text-align: right;
}

.gigya-screen .gigya-footer-submit {
    padding-bottom: 30px;
    padding-right: 30px;
    display: block;
}

.link-step {
    cursor: pointer;
    /*text-decoration:underline;*/
    /*font-size: 0.875em;*/
    /*font-family: OpenSans-Regular,Helvetica,Arial,sans-serif;*/
    color: #4c4c4c;
}

.link-step :hover {
    color: #1086c6;
    /*text-decoration:none;*/
}

.link-step :active {
    color: #064767;
}

.gigya-screen .gigya-subheader-label {
    text-align: right;
    font-size: 1em;
    font-family: OpenSans-Regular, Helvetica, Arial, sans-serif;
    color: #4c4c4c;
}

.gigya-screen-dialog-top {
    position: relative;
}

.gigya-screen-dialog-top .gigya-screen-dialog-caption {
    font-weight: normal;
    font-size: 18px;
    color: #1f2122;
    text-align: center;
    padding-top: 30px;
    line-height: 24px;
    padding-bottom: 20px;
}

div.gigya-screen-dialog-close {
    height: 20px;
    width: 20px;
    background-image: url('https://connectamericas.com/sites/all/themes/connectamericas/images/forms-icons.svg');
    background-size: 140px 20px;
    background-repeat: no-repeat;
    background-position: -80px 10px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

div.gigya-screen-dialog-close:hover {
    background-position: -100px 10px;
}

.gigya-screen-dialog-bottom {
    background: #eee;
    border-top: #ddd 1px solid;
    line-height: 20px;
    font-size: 11px;
    text-align: center;
    padding: 10px 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #666;
}

.gigya-screen-dialog-bottom a {
    text-decoration: underline;
    color: #666;
}

.gigya-screen-dialog-bottom a:hover {
    color: #1f2122
}

/*MMPB*/
/* FIXED title of registration form */

.gigyasocialsharetitle {
    font-size: 1em;
    /*color:#4c4c4c;*/
    font-weight: 600;
}

.gigyasocialshareintro {
    font-size: 0.875em;
    /*color:#4c4c4c;*/
    padding-left: 20px;
}

.gigyasocialsharetext {
    font-size: 1em;
    /*color:#4c4c4c;*/
    font-weight: 600;
    line-height: 50px;
}

.gigyasocialsharecell .gigyasocialsharetext a {
    float: left;
}

.gigyasocialsharecell .gigyasocialsharetext span {
    float: left;
    padding-left: 0.625em;
}

.gigyasocialsharecell {
    width: 250px;
    padding-left: 75px;
    padding-bottom: 20px;
}

h1.gigya-screen-caption {
    border-bottom: 1px solid #DBDBDB;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 45px;
    padding-bottom: 5px;
}


/* include in js */
.gigya-reset *,
div.gigya-reset,
.gigya-reset span,
.gigya-reset a:hover,
.gigya-reset a:visited,
.gigya-reset a:link,
.gigya-reset a:active {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    border-radius: 0 0 0 0;
    color: #333333;
    float: none;
    font-family: arial;
    font-size: 12px;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    width: auto;
}

.gigya-screen input.gigya-error,
.gigya-screen input.gigya-error {
    border-color: #DD4B39;
}

div.gigya-password-strength {
    width: 250px;
}

span.gigya-passwordStrength-text {
    font-weight: bold;
}

div.gigya-passwordStrength-text-requirements {
    margin-top: 8px;
    width: 100%;
}

span.gigya-passwordStrength-scoreText {
    font-weight: normal;
    margin-left: 3px;
}

div.gigya-passwordStrength-bar {
    background-color: #F3F3F3;
    height: 5px;
    margin-top: 8px;
    width: 90%;
}

div.gigya-passwordStrength-bar-too_weak {
    background-color: #DE321D;
    height: 5px;
    width: 5%;
}

div.gigya-passwordStrength-bar-weak {
    background-color: #DE321D;
    height: 5px;
    width: 25%;
}

div.gigya-passwordStrength-bar-fair {
    background-color: #4995E0;
    height: 5px;
    width: 50%;
}

div.gigya-passwordStrength-bar-strong {
    background-color: #76C261;
    height: 5px;
    width: 75%;
}

div.gigya-passwordStrength-bar-very_strong {
    background-color: #76C261;
    height: 5px;
    width: 100%;
}

div.gigya-captcha {
    min-height: 129px;
    min-width: 318px;
}

div.gigya-social-login {
    min-height: 120px;
    min-width: 110px;
}

div.gigya-loginID-availability {
    background-position: left center;
    background-repeat: no-repeat;
    line-height: 18px;
}

div.gigya-loginID-availability-available {
    background-image: url("http://cdn.gigya.com/gs/i/accounts/ok.png");
    color: #4FA373;
    padding-left: 17px;
}

div.gigya-loginID-availability-not-available {
    background-image: url("http://cdn.gigya.com/gs/i/accounts/error.png");
    color: #DD4B39;
    padding-left: 16px;
}

div.gigya-loginID-availability-progress {
    background-image: url("http://cdn.gigya.com/gs/i/accounts/smallLoader.gif");
    color: #666666;
    padding-left: 20px;
}

div.gigya-profile-photo {
    border-radius: 3px 3px 3px 3px;
    min-height: 64px;
    min-width: 64px;
}

div.gigya-screen-dimmer {
    background-color: #FFFFFF;
    opacity: 0.5;
    position: absolute;
}

div.gigya-screen-loader {
    background: url("http://cdn.gigya.com/gs/i/accounts/bigLoader.gif") no-repeat scroll center center rgba(0, 0, 0, 0);
    position: absolute;
}

div.gigya-screen-dialog {
    background-color: #FFFFFF;
    border: 8px solid #CCCCCC;
    border-radius: none !important;
    display: inline-block;
    left: 25px;
    position: absolute;
    top: 25px;
}

div.gigya-screen-dialog-mobile {
    background-color: #FFFFFF;
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
}

div.gigya-secret-question {
    display: none;
}

div.gigya-photo-upload {
    height: 63px;
    width: 300px;
}

div.gigya-photo-upload-button {
    background: -moz-linear-gradient(center top, #FFFFFF, #F2F2F2) repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 1px solid #CECECE;
    border-radius: 4px 4px 4px 4px;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 28px;
    margin: 0 8px 8px 0;
    padding: 0 12px;
}

div.gigya-photo-upload-button:hover {
    background: -moz-linear-gradient(center top, #F2F2F2, #FFFFFF) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

div.gigya-photo-upload-image {
    background-color: #F2F2F2;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px 6px 6px 6px;
    float: left;
    height: 64px;
    margin-right: 8px;
    width: 64px;
}

div.gigya-photo-upload-path {
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    padding-right: 20px;
}

h1.gigya-screen-caption {
    border-bottom: 1px solid #DBDBDB;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 45px;
    padding-bottom: 5px;
}

.gigya-error-display {
    display: none;
}

.gigya-error-display-active {
    display: block;
}

/* EOF: include in js */

#userScreensDiv_content {
    width: 60% !important;
    margin: 0 auto;
}

.gigya-label .gigya-social-label-icon {
    float: left;
    height: 1.5625em;
    margin-right: 1em;
}

/*.gigya-label .gigya-social-label-text {
    float: left;
}*/

div#content {
    background-color: #FAFAFA;
    background-image: none;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0 0;
}

#birthYearSelect {
    width: 6.3em;
}

#gigya-register-screen div[gigid=showScreenSet_showLoginUI] table[id^="gig"],
#gigya-login-screen div[gigid=showScreenSet_showLoginUI] table[id^="gig"] {
    height: 100px;
}

#gigya-register-screen div[gigid=showScreenSet_showLoginUI] table[id^="gig"] table td,
#gigya-login-screen div[gigid=showScreenSet_showLoginUI] table[id^="gig"] table td {
    padding: 0 5px 5px 0;
}

.gigya-photo-upload-form input {
    width: 100%;
    font-size: inherit !important;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #DB1E04;
    margin-left: 10px;
}

.join_button_header {
    font-family: Montserrat-Regular !important;
    font-size: 15px !important;
    font-weight: normal !important;
    background: #fe872c !important;
    text-transform: none !important;
    width: 225px;
    height: 34px;

}

.borderRight {
    border-right: solid 1px #c6c6c6;
}

#company_autocomplete {
    /* background-image: url(/connect-americas-idb/misc/throbber.gif) !important; */
}

.autocompleteClass div label {
    display: block;
    /*font-weight: bold;*/
    /*font-size: 12px;*/
    font-size: 1em;
    font-family: OpenSans-Semibold, Helvetica, Arial, sans-serif;
    color: #4c4c4c;
}

h2.gigya-composite-control-header {
    color: #8b8b8b;
    font-weight: lighter;
}

.gigya-input-submit {
    padding: 0 1em;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    color: #FFF;
    background: #EE7722;
}

.gigya-input-submit:hover {
    background: #FF5400;
}

.gigya-input-submit:active {
    background: #F89E59;
}

/* X */

#login-modal {
    background: #ffffff;
    width: 100% !important;
    height: 100% !important;
    bottom: auto !important;
}

#login-modal .gigya-screen-dialog-inner {
    background: #FFF;
    border-radius: 3px;
}

#login-modal .gigya-screen-dialog-inner .gigya-screen-content {
    /*min-height: 161px !important;*/
}

#login-modal .gigya-screen-dialog-inner .continue-button-wrapper {
    margin-top: -10px;
    padding: 0 20px 20px 20px;
    text-align: center;
}

#login-modal .gigya-screen-dialog-inner .continue-button-wrapper a {
    margin-top: 20px;
    display: block;
    padding: 10px 0;
    text-align: center;
    color: #FFF;
    border-radius: 3px;
    text-decoration: none;
    background: #7EC1CA;
}

#login-iframe{
    width: 400px;
    height: 301px;
    border: none;
}

/* Responsive Fixes */
#content-wrapper.user-register-page-footer {
    height: auto;
    width: 100%;
    max-width: 1050px;
    margin: auto;
    padding-top: 0 !important;
}

#content-wrapper.user-register-page-footer #content p {
    width: 100%;
    text-align: center;
    margin: 20px auto;
    display: inline-block;
}
@media screen and (max-width: 480px) {
    #content-wrapper.user-register-page-footer {
        height: 845px;
    }

    #user-register-form .form-wrapper#edit-field-name-user,
    #user-register-form .form-wrapper#edit-field-last-name-user {
        width: 100%;
        max-width: none;
    }
}
