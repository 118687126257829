@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,700,300,600,800,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

/* Structure */
/* =============================
======== HEADER DESKTOP ========
============================= */
#fixed-menu {
    min-width: 900px;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    height: 60px;
    overflow: visible;
    background-color: transparent;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
}

#fixed-menu .navbar-left {
    float: left;
}

#fixed-menu .navbar-right {
    float: right;
}

#fixed-menu .element-middle {
    height: 60px;
    line-height: 60px;
}

#fixed-menu.darkHeader {
    background: #FFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 180ms linear;
    -moz-transition: background-color 180ms linear;
    -o-transition: background-color 180ms linear;
    -ms-transition: background-color 180ms linear;
    transition: background-color 180ms linear;
}

#fixed-menu #logo-wrapper {
    float: left;
}

#fixed-menu.darkHeader #logo-wrapper #logo {
    background: transparent url(images/logoConnectAmericas.svg) no-repeat !important;
    width: 146px;
    height: 55px !important;
    background-size: 126px !important;
    background-position: center 12px !important;
    -webkit-transition: width 180ms;
    -moz-transition: width 180ms;
    transition: width 180ms;
}


@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #user-menu {
    background: transparent;
    white-space: nowrap;
    float: left;
    letter-spacing: 1px;
    margin-right: 14px;
}

#fixed-menu #user-menu .login,
#fixed-menu #user-menu .signup,
#fixed-menu #user-menu .submenu {
    display: inline-block;
    padding: 0 12px;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
}

#fixed-menu #user-menu .login {
    font-size: 12px;
    text-align: center;
}

#fixed-menu #user-menu .login:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .signup {
    background: transparent;
    transition: background-color 0.2s;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-right: 10px;
}

#fixed-menu #user-menu .signup:hover {
    color: #fff !important;
    background-color: #ee7722;
    border-color: #ee7722 !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#fixed-menu #user-menu .submenu {
    background: transparent;
    transition: background-color 0.2s;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-right: 10px;
}

#fixed-menu #user-menu .submenu:hover {
    color: #fff !important;
    background-color: #ee7722;
    border-color: #ee7722 !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#fixed-menu #user-menu .user_info {
    height: 60px;
    overflow: visible;
    white-space: nowrap;
    font-family: "Open Sans", Arial, sans-serif !important;
    margin-right: -14px;
    margin-left: 12px;
}

#fixed-menu #user-menu .user_info .user-menu {
    float: left;
    position: relative;
    display: block;
    height: 60px;
    overflow: visible;
    background: transparent;
    white-space: nowrap;
    max-width: 335px;
}

#fixed-menu #user-menu .user_info .user-menu a:hover {
    cursor: pointer;
}

#fixed-menu #user-menu .user_info .user-menu .picture {
    width: 50px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;
    background: #EEE url(images/user_placeholder.svg) center center no-repeat;
    background-size: contain !important;
}

#fixed-menu #user-menu .user_info .user-menu .has-picture {
    background-size: cover !important;
}

#fixed-menu #user-menu .user_info .user-menu .name {
    width: 100%;
    max-width: 290px;
    height: 60px;
    margin-top: -60px;
    padding: 0 35px 0 65px;
    font-size: 12px;
    color: #1F2122;
    background: transparent url(images/arrowGrey.svg) center right 7px no-repeat;
    cursor: pointer;
    min-width: 124px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

@media (max-width: 1200px) {
    #fixed-menu #user-menu .user_info .user-menu .name {
        padding: 0;
        min-width: 82px;
    }

    #fixed-menu #user-menu .user_info .user-menu .name span {
        display: none;
    }
}

#fixed-menu #user-menu .user_info .user-menu .arrow {
    background: transparent url(images/arrowGrey.svg) center right 7px no-repeat;
    width: 20px;
    float: right;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu {
    display: none;
    position: absolute;
    top: 60px;
    left: initial;
    right: 0;
    min-width: 206px;
    z-index: 1100;
    padding: 10px 12px;
    background: rgba(45, 155, 174, 0.9);
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    #fixed-menu #user-menu .user_info .user-menu ul.menu {
        left: initial !important;
    }
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.last {
    border-bottom: 0;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li a {
    line-height: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li a:hover {
    border-bottom: 1px solid #fff;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared .profile {
    float: left;
    margin: 4px 0 0 0;
    max-width: 138px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared a.edit {
    width: 35px;
    height: 34px;
    overflow: hidden;
    float: right;
    background: url(images/user-edit.svg) center center no-repeat;
    background-size: 16px 16px;
    text-indent: -1000px;
    padding: 0 !important;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared a.edit:hover {
    background-color: rgba(45, 155, 174, 0.9);
    border-bottom: none;
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover),
#fixed-menu #user-menu .user_info .user-menu.open {
    background: rgba(45, 155, 174, 0.9);
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover) .name,
#fixed-menu #user-menu .user_info .user-menu.open .name {
    color: #FFF;
    background: transparent url(images/arrowWhite.svg) center right 7px no-repeat;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover) ul.menu,
#fixed-menu #user-menu .user_info .user-menu.open ul.menu {
    display: block;
}

#fixed-menu #user-menu .user_info .notifications {
    float: left;
    width: 50px;
    height: 60px;
    border-right: solid 1px #EDEDED;
    border-left: solid 1px #EDEDED;
    position: relative;
    background: transparent url(images/notifications.svg) center center no-repeat;
}

#fixed-menu #user-menu .user_info .notifications:hover {
    cursor: pointer;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    background: rgba(45, 155, 174, 0.9) url(images/notificationsActive.svg) center center no-repeat;
    border-color: rgba(45, 155, 174, 0.9);
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #user-menu .user_info .notifications:hover .notifications-info {
    display: block;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn {
    position: relative;
    display: block;
    width: 50px;
    height: 60px;
    line-height: 15px;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn span {
    position: absolute;
    top: 15px;
    right: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    color: #FFF;
    background: #F10F0F;
    letter-spacing: 0;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn:hover {
    border-color: rgba(45, 155, 174, 0.9);
}

#fixed-menu #user-menu .user_info .notifications .notifications-info {
    position: absolute;
    top: 60px;
    right: -1px;
    width: 270px;
    display: none;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-arrow {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 9999;
    margin-left: 215px;
    background: url(images/arrowGrey.svg) no-repeat 0 11px;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content {
    position: absolute;
    width: 100%;
    background-color: rgba(45, 155, 174, 0.9);
    color: #fff;
    padding: 10px 0;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section {
    border-bottom: 1px solid #ccc;
    float: left;
    padding: 8px 12px;
    word-break: break-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link {
    float: left;
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    white-space: normal;
    line-height: 20px;
    word-break: keep-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link a {
    color: white;
    font-weight: 800;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section-more {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section-more:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-row {
    color: #fff;
    padding: 10px 12px;
    border-bottom: solid 1px #eee;
    font-size: 14px !important;
    line-height: 1.7;
    word-break: break-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-row span {
    font-weight: 700;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .no-notifications {
    padding: 10px 12px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: auto;
    font-weight: 400;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-see-all {
    padding: 10px 12px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-link.seen .notifications-row {
    color: #888888;
}

#fixed-menu.darkHeader #user-menu .signup {
    color: #fff !important;
    background-color: #ee7722 !important;
    border-color: #ee7722 !important;
}

#fixed-menu.darkHeader #user-menu .login {
    color: #000 !important;
}

#fixed-menu #help-menu-wrapper {
    float: left;
    cursor: pointer;
    display: block;
    width: 50px;
    height: 60px;
    background: transparent url(images/faqsWhite.svg) center center no-repeat;
}

#fixed-menu #help-menu-wrapper a:hover {
    cursor: pointer;
}

#fixed-menu #help-menu-wrapper:hover {
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    background: rgba(45, 155, 174, 0.9) url(images/faqsActive.svg) center center no-repeat !important;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #help-menu-wrapper #help-menu {
    position: relative;
    width: 50px;
    height: 60px;
}

#fixed-menu #help-menu-wrapper #help-menu a.menu-button {
    display: block;
    width: 50px;
    height: 60px;
    text-align: center;
}

#fixed-menu #help-menu-wrapper #help-menu a.menu-button img {
    vertical-align: middle;
}

#fixed-menu #help-menu-wrapper #help-menu .menu {
    padding: 10px 12px;
    background: rgba(45, 155, 174, 0.9);
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #help-menu-wrapper #help-menu .menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #help-menu-wrapper #help-menu>ul {
    position: absolute;
    top: 60px;
    right: 0px;
    display: none;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover) a.menu-button,
#fixed-menu #help-menu-wrapper #help-menu.open a.menu-button {
    color: #FFF;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul,
#fixed-menu #help-menu-wrapper #help-menu.open>ul {
    display: block;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul li a,
#fixed-menu #help-menu-wrapper #help-menu.open>ul li a {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    white-space: nowrap;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul li a:hover,
#fixed-menu #help-menu-wrapper #help-menu.open>ul li a:hover {
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
}

#fixed-menu.darkHeader #help-menu-wrapper {
    background: transparent url(images/faqsGrey.svg) center center no-repeat;
}

#fixed-menu #ca-lang-selector {
    float: left;
    position: relative;
    height: 60px;
    width: 50px;
    overflow: visible;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    margin-right: 10px;
    background: transparent url(images/arrowWhite.svg) 26px 19px no-repeat;
}

#fixed-menu #ca-lang-selector a:hover {
    cursor: pointer;
}

#fixed-menu #ca-lang-selector .current {
    height: 60px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
}

#fixed-menu #ca-lang-selector .current span {
    margin-left: -16px;
}

#fixed-menu #ca-lang-selector .current img {
    margin: -5px -6px;
}

#fixed-menu #ca-lang-selector ul.menu {
    position: absolute;
    width: 50px;
    text-align: center;
    color: #FFF;
    display: none;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #ca-lang-selector ul.menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #ca-lang-selector ul.menu li a {
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    white-space: nowrap;
}

#fixed-menu #ca-lang-selector ul.menu li a:hover {
    border-bottom: 1px solid #fff;
}

#fixed-menu #ca-lang-selector:hover {
    color: #fff !important;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    background: rgba(45, 155, 174, 0.9) url(images/arrowWhite.svg) 26px 19px no-repeat;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #ca-lang-selector:hover ul.menu {
    display: block;
    padding: 10px 12px;
    background: rgba(45, 155, 174, 0.9);
}

#fixed-menu.darkHeader #ca-lang-selector {
    color: #000 !important;
    background: transparent url(images/arrowGrey.svg) 26px 19px no-repeat;
}

#fixed-menu.darkHeader #ca-lang-selector:hover {
    background: rgba(45, 155, 174, 0.9) url(images/arrowWhite.svg) 26px 19px no-repeat;
    color: #fff !important;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu {
    min-width: 900px;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    height: 60px;
    overflow: visible;
    background-color: transparent;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
}

@media screen and (max-width: 1240px) {
    #fixed-menu {
        width: 100%;
        min-width: 300px;
    }
}

#fixed-menu .navbar-left {
    float: left;
}

@media screen and (max-width: 1240px) {
    #fixed-menu .navbar-left {
        margin-left: 25px;
    }
}

#fixed-menu .navbar-right {
    float: right;
}

@media screen and (max-width: 1240px) {
    #fixed-menu .navbar-right {
        margin-right: 25px;
    }
}

@media screen and (max-width: 800px) {
    #fixed-menu .navbar-right {
        display: none;
    }
}

#fixed-menu .mobilehamburguer {
    display: none;
}

@media screen and (max-width: 800px) {
    #fixed-menu .mobilehamburguer {
        display: block;
        right: 30px;
        position: absolute;
        top: 0px;
    }
}

#fixed-menu .mobilehamburguer:hover {
    cursor: pointer;
}

#fixed-menu .mobilehamburguer .sandwich {
    width: 60px;
    height: 60px;
    background: url(images/sandwich.svg) 16px -40px no-repeat;
    display: inline-block;
    vertical-align: middle;
}

#fixed-menu .sub {
    display: none;
    left: 0;
    right: 0;
    position: absolute;
    top: 60px;
    background: #31abc9;
}

@media screen and (min-width: 800px) {
    #fixed-menu .sub {
        display: none !important;
    }
}

#fixed-menu .sub ul.ulsub {
    width: calc(100% - 50px);
    float: left;
}

#fixed-menu .sub ul.ulsub li {
    height: 47px;
}

#fixed-menu .sub ul.ulsub li a {
    display: block;
    height: 47px;
    line-height: 47px;
    margin-left: 30px;
    color: #fff;
}

#fixed-menu.darkHeader {
    background: #FFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 180ms linear;
    -moz-transition: background-color 180ms linear;
    -o-transition: background-color 180ms linear;
    -ms-transition: background-color 180ms linear;
    transition: background-color 180ms linear;
}

#fixed-menu.clearHeader {
    background: transparent;
    -webkit-transition: background-color 180ms linear;
    -moz-transition: background-color 180ms linear;
    -o-transition: background-color 180ms linear;
    -ms-transition: background-color 180ms linear;
    transition: background-color 180ms linear;
}

#fixed-menu #logo-wrapper {
    float: left;
}

#fixed-menu #logo-wrapper #logo {
    display: inline-block;
    width: 84px;
    -webkit-transition: width 180ms;
    -moz-transition: width 180ms;
    transition: width 180ms;
    height: 55px;
    background: transparent url(images/logoInitial.svg) no-repeat;
    background-origin: content-box;
    background-position: center 1px;
    background-size: 56px;
}

#fixed-menu.darkHeader .navbar-wrapper {
    max-width: 1200px;
    margin: auto;
}

#fixed-menu #explore-menu-wrapper {
    float: left;
}

#fixed-menu #explore-menu-wrapper #explore-menu {
    display: block;
    height: 60px;
    line-height: 60px;
    font-size: 12px !important;
    text-transform: uppercase;
    vertical-align: middle;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
}

#fixed-menu #explore-menu-wrapper #explore-menu img {
    margin: -8px 18px;
}

#fixed-menu #explore-menu-wrapper #explore-menu .title-menu {
    margin-left: 8px;
}

#fixed-menu #explore-menu-wrapper #explore-menu .sandwich {
    width: 60px;
    height: 100%;
    background: url(images/sandwich.svg) 16px -40px no-repeat;
    display: inline-block;
    vertical-align: middle;
}

#fixed-menu #explore-menu-wrapper #explore-menu.open .sandwich {
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    background: rgba(45, 155, 174, 0.9) url(images/sandwich.svg) 16px 12px no-repeat;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #explore-menu-wrapper #explore-menu.open span {
    color: #039bb5;
}

#fixed-menu.darkHeader #explore-menu-wrapper a {
    color: #000 !important;
}

#fixed-menu #header-search {
    padding-right: 6px;
    float: left;
}

#fixed-menu #header-search form {
    position: relative;
    width: 100%;
    height: 60px;
    vertical-align: middle;
}

#fixed-menu #header-search form>div {
    height: 50px;
    float: right;
}

#fixed-menu #header-search form>div .form-item-criteria-header {
    height: 60px;
    vertical-align: middle;
    width: 280px;
    margin-top: 10px;
    float: left;
}

#fixed-menu #header-search form>div .form-item-criteria-header input {
    color: #888;
    width: 100% !important;
    height: 40px !important;
    font-family: "Open Sans", Arial, sans-serif !important;
    border: 1px solid #ccc;
    border-radius: 0;
    border-left: none;
    border-right: none;
    background-color: #fff;
    font-size: 12px !important;
    margin-top: 0;
}

#fixed-menu #header-search form>div .selector {
    position: relative;
    vertical-align: top;
    visibility: visible;
    height: 60px;
    overflow: visible;
    opacity: 1;
    float: left;
    -webkit-transition: width 0.2s;
    -moz-transition: width 0.2s;
    transition: width 0.2s;
}

#fixed-menu #header-search form>div .selector.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transition: width 0.2s;
    -moz-transition: width 0.2s;
    transition: width 0.2s;
}

#fixed-menu #header-search form>div .selector span.current {
    display: block;
    width: auto;
    height: 40px;
    line-height: 40px;
    padding: 0 10px 0 30px;
    margin-top: 10px;
    font-size: 12px;
    color: #888;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;
    border: 1px solid #ccc;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right-color: #EFEFEF;
    background: #fff url(images/arrowGrey.svg) left 5px top 10px no-repeat;
}

#fixed-menu #header-search form>div .selector ul.menu {
    position: absolute;
    top: 50px;
    visibility: hidden;
    background: #FFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#fixed-menu #header-search form>div .selector ul.menu.open {
    visibility: visible;
}

#fixed-menu #header-search form>div .selector ul.menu li a {
    display: block;
    padding: 0 15px 0 10px;
    line-height: 30px;
    font-size: 12px;
    text-align: left;
    color: #1F2122;
    white-space: nowrap;
}

#fixed-menu #header-search form>div .selector ul.menu li a:hover {
    background: #F0F0F0;
}

#fixed-menu #header-search form>div .form-submit {
    visibility: visible;
    width: auto;
    height: 40px;
    margin-top: 10px;
    padding: 0 20px;
    background: #fff url(images/search.svg) 6px -44px no-repeat !important;
    outline: 0;
    cursor: pointer;
    opacity: 1;
    text-indent: -99999px;
    border: 1px solid #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: none;
    float: left;
}

#fixed-menu.darkHeader #header-search .selector span.current {
    color: #888;
    background: #fff url(images/arrowGrey.svg) left 5px top 10px no-repeat;
}

#fixed-menu #user-menu {
    background: transparent;
    white-space: nowrap;
    float: left;
    letter-spacing: 1px;
    margin-right: 0;
}

#fixed-menu #user-menu .login,
#fixed-menu #user-menu .signup,
#fixed-menu #user-menu .submenu {
    display: inline-block;
    padding: 0 12px;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
}

#fixed-menu #user-menu .signup {
    font-size: 12px;
    text-align: center;
}

#fixed-menu #user-menu .signup:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .login {
    background: transparent;
    transition: background-color 0.2s;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-right: 10px;
}

#fixed-menu #user-menu .submenu {
    font-size: 12px;
    text-align: center;
}

#fixed-menu #user-menu .submenu:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .user_info {
    height: 60px;
    overflow: visible;
    white-space: nowrap;
    font-family: "Open Sans", Arial, sans-serif !important;
    margin-right: -14px;
    margin-left: 12px;
}

#fixed-menu #user-menu .user_info .user-menu {
    float: left;
    position: relative;
    display: block;
    height: 60px;
    overflow: visible;
    background: transparent;
    white-space: nowrap;
    max-width: 335px;
}

#fixed-menu #user-menu .user_info .user-menu a:hover {
    cursor: pointer;
}

#fixed-menu #user-menu .user_info .user-menu .picture {
    width: 50px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;
    background: #EEE url(images/user_placeholder.svg) center center no-repeat;
    background-size: contain !important;
}

#fixed-menu #user-menu .user_info .user-menu .has-picture {
    background-size: cover !important;
}

#fixed-menu #user-menu .user_info .user-menu .name {
    width: 100%;
    max-width: 290px;
    height: 60px;
    margin-top: -60px;
    padding: 0 35px 0 65px;
    font-size: 12px;
    color: #1F2122;
    background: transparent url(images/arrowGrey.svg) center right 7px no-repeat;
    cursor: pointer;
    min-width: 124px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

@media (max-width: 1200px) {
    #fixed-menu #user-menu .user_info .user-menu .name {
        padding: 0;
        min-width: 82px;
    }

    #fixed-menu #user-menu .user_info .user-menu .name span {
        display: none;
    }
}

#fixed-menu #user-menu .user_info .user-menu .arrow {
    background: transparent url(images/arrowGrey.svg) center right 7px no-repeat;
    width: 20px;
    float: right;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu {
    display: none;
    position: absolute;
    top: 60px;
    left: initial;
    right: 0;
    min-width: 206px;
    z-index: 1100;
    padding: 10px 12px;
    background: rgba(45, 155, 174, 0.9);
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    #fixed-menu #user-menu .user_info .user-menu ul.menu {
        left: initial !important;
    }
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.last {
    border-bottom: 0;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li a {
    line-height: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li a:hover {
    border-bottom: 1px solid #fff;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared .profile {
    float: left;
    margin: 4px 0 0 0;
    max-width: 138px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared a.edit {
    width: 35px;
    height: 34px;
    overflow: hidden;
    float: right;
    background: url(images/user-edit.svg) center center no-repeat;
    background-size: 16px 16px;
    text-indent: -1000px;
    padding: 0 !important;
}

#fixed-menu #user-menu .user_info .user-menu ul.menu li.shared a.edit:hover {
    background-color: rgba(45, 155, 174, 0.9);
    border-bottom: none;
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover),
#fixed-menu #user-menu .user_info .user-menu.open {
    background: rgba(45, 155, 174, 0.9);
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover) .name,
#fixed-menu #user-menu .user_info .user-menu.open .name {
    color: #FFF;
    background: transparent url(images/arrowWhite.svg) center right 7px no-repeat;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #user-menu .user_info .user-menu:hover:not(.prevent-hover) ul.menu,
#fixed-menu #user-menu .user_info .user-menu.open ul.menu {
    display: block;
}

#fixed-menu #user-menu .user_info .notifications {
    float: left;
    width: 50px;
    height: 60px;
    border-right: solid 1px #EDEDED;
    border-left: solid 1px #EDEDED;
    position: relative;
    background: transparent url(images/notifications.svg) center center no-repeat;
}

#fixed-menu #user-menu .user_info .notifications:hover {
    cursor: pointer;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    background: rgba(45, 155, 174, 0.9) url(images/notificationsActive.svg) center center no-repeat;
    border-color: rgba(45, 155, 174, 0.9);
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #user-menu .user_info .notifications:hover .notifications-info {
    display: block;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn {
    position: relative;
    display: block;
    width: 50px;
    height: 60px;
    line-height: 15px;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn span {
    position: absolute;
    top: 15px;
    right: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    color: #FFF;
    background: #F10F0F;
    letter-spacing: 0;
}

#fixed-menu #user-menu .user_info .notifications a.notifications-btn:hover {
    border-color: rgba(45, 155, 174, 0.9);
}

#fixed-menu #user-menu .user_info .notifications .notifications-info {
    position: absolute;
    top: 60px;
    right: -1px;
    width: 270px;
    display: none;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-arrow {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 9999;
    margin-left: 215px;
    background: url(images/arrowGrey.svg) no-repeat 0 11px;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content {
    position: absolute;
    width: 100%;
    background-color: rgba(45, 155, 174, 0.9);
    color: #fff;
    padding: 10px 0;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section {
    border-bottom: 1px solid #ccc;
    float: left;
    padding: 8px 12px;
    word-break: break-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link {
    float: left;
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    white-space: normal;
    line-height: 20px;
    word-break: keep-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section .notification-link a {
    color: white;
    font-weight: 800;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section-more {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-section-more:hover {
    text-decoration: underline;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-row {
    color: #fff;
    padding: 10px 12px;
    border-bottom: solid 1px #eee;
    font-size: 14px !important;
    line-height: 1.7;
    word-break: break-all;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-row span {
    font-weight: 700;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .no-notifications {
    padding: 10px 12px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: auto;
    font-weight: 400;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notifications-see-all {
    padding: 10px 12px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

#fixed-menu #user-menu .user_info .notifications .notifications-info .notifications-content .notification-link.seen .notifications-row {
    color: #888888;
}

#fixed-menu.darkHeader #user-menu .login,
#fixed-menu.darkHeader #user-menu .signup,
#fixed-menu.darkHeader #user-menu .submenu,
#fixed-menu.darkHeader #user-menu #submenu-search {
    display: inline-block;
    padding: 0 12px;
    white-space: nowrap;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-transform: none !important;
}

#fixed-menu.darkHeader #user-menu .signup {
    /* line-height: 94px; */
    text-align: center;
    margin-right: 25px;
    color: #fff;
    font-weight: bold;
}

#fixed-menu.darkHeader #user-menu .signup:hover {
    text-decoration: none;
    color: #EE7722 !important;
    background: none !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#fixed-menu.darkHeader #user-menu .submenu {
    line-height: 60px;
    text-align: center;
    color: #000 !important;
}

#fixed-menu.darkHeader #user-menu .submenu:hover {
    text-decoration: none;
    color: #EE7722 !important;
    background: none !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#fixed-menu.darkHeader #user-menu .login {
    background: transparent;
    transition: background-color 0.2s;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #2EAEC1;
    border-radius: 19px;
    margin-right: 25px;
    color: #fff !important;
    background-color: #2EAEC1 !important;
    padding: 0 20px;
}

#fixed-menu.darkHeader #user-menu .login:hover {
    color: #2EAEC1 !important;
    background-color: #fff !important;
    border-color: #2EAEC1 !important;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

#fixed-menu.darkHeader #user-menu #submenu-search {
    background-image: url(images/search_ho.svg);
    background: url(images/search.svg) 0 22px no-repeat;
    /* line-height: 94px; */
    text-align: center;
    color: #2EAEC1 !important;
}

#fixed-menu.darkHeader #user-menu #submenu-search span {
    display: inline;
    margin-left: 8px;
}

#fixed-menu.darkHeader #user-menu #submenu-search:hover {
    text-decoration: none;
    background-image: url(images/search_ho.svg);
    color: #EE7722 !important;
}

#fixed-menu.darkHeader #user-menu #submenu-top:hover {
    color: #EE7722 !important;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul {
    opacity: 0;
    overflow: hidden;
    height: 0;
    position: absolute;
    background-color: #fff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1607843137);
    width: auto;
    margin-top: 10px;
    margin-left: 52px;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul:before {
    content: "";
    position: absolute;
    top: -20px;
    left: calc(50% - 7px);
    width: 31px;
    height: 20px;
    background-image: url(images/triangle.png);
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul h5 {
    color: #2EAEC1;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-transform: uppercase;
    border-bottom: 1px solid #D8D8D8;
    margin: 20px 30px 0;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul ul {
    list-style: none;
    margin: 20px 30px;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul ul li {
    color: #171717;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 6px;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul ul li a {
    color: #171717;
}

#fixed-menu.darkHeader #user-menu #submenu-top-ul ul li a:hover {
    color: #2EAEC1;
}

#fixed-menu #help-menu-wrapper {
    float: left;
    cursor: pointer;
    display: block;
    width: 50px;
    height: 60px;
    background: transparent url(images/faqsWhite.svg) center center no-repeat;
}

#fixed-menu #help-menu-wrapper a:hover {
    cursor: pointer;
}

#fixed-menu #help-menu-wrapper:hover {
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
    background: rgba(45, 155, 174, 0.9) url(images/faqsActive.svg) center center no-repeat !important;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fixed-menu #help-menu-wrapper #help-menu {
    position: relative;
    width: 50px;
    height: 60px;
}

#fixed-menu #help-menu-wrapper #help-menu a.menu-button {
    display: block;
    width: 50px;
    height: 60px;
    text-align: center;
}

#fixed-menu #help-menu-wrapper #help-menu a.menu-button img {
    vertical-align: middle;
}

#fixed-menu #help-menu-wrapper #help-menu .menu {
    padding: 10px 12px;
    background: rgba(45, 155, 174, 0.9);
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #help-menu-wrapper #help-menu .menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #help-menu-wrapper #help-menu>ul {
    position: absolute;
    top: 60px;
    right: 0px;
    display: none;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover) a.menu-button,
#fixed-menu #help-menu-wrapper #help-menu.open a.menu-button {
    color: #FFF;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul,
#fixed-menu #help-menu-wrapper #help-menu.open>ul {
    display: block;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul li a,
#fixed-menu #help-menu-wrapper #help-menu.open>ul li a {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    white-space: nowrap;
}

#fixed-menu #help-menu-wrapper #help-menu:hover:not(.prevent-hover)>ul li a:hover,
#fixed-menu #help-menu-wrapper #help-menu.open>ul li a:hover {
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
}

#fixed-menu.darkHeader #help-menu-wrapper {
    background: transparent url(images/faqsGrey.svg) center center no-repeat;
}

#fixed-menu #ca-lang-selector {
    float: left;
    position: relative;
    height: 60px;
    width: 50px;
    overflow: visible;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    margin: 10px;
    background: transparent url(images/arrowWhite.svg) 26px 19px no-repeat;
}

#fixed-menu #ca-lang-selector a:hover {
    cursor: pointer;
}

#fixed-menu #ca-lang-selector .current {
    height: 60px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
}

#fixed-menu #ca-lang-selector .current span {
    margin-left: -16px;
}

#fixed-menu #ca-lang-selector .current img {
    margin: -5px -6px;
}

#fixed-menu #ca-lang-selector ul.menu {
    position: absolute;
    top: 60px;
    width: 50px;
    text-align: center;
    color: #FFF;
    display: none;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

#fixed-menu #ca-lang-selector ul.menu.open-lang {
    display: block;
    background: #31abc9;
}

#fixed-menu #ca-lang-selector ul.menu li {
    height: 36px !important;
    line-height: 36px;
}

#fixed-menu #ca-lang-selector ul.menu li a {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #FFF;
    white-space: nowrap;
}

#fixed-menu #ca-lang-selector ul.menu li a:hover {
    border-bottom: 1px solid #fff;
}

@media (min-width: 800px) {
    #fixed-menu #ca-lang-selector:hover {
        color: #fff !important;
        animation-name: slidenavAnimation;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        -webkit-animation-name: slidenavAnimation;
        -webkit-animation-duration: 0.3s;
        -webkit-animation-timing-function: ease-out;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-name: slidenavAnimation;
        -moz-animation-duration: 0.3s;
        -moz-animation-timing-function: ease-out;
        -moz-animation-fill-mode: forwards;
        background: rgba(45, 155, 174, 0.9) url(images/arrowWhite.svg) 26px 19px no-repeat;
    }

    @keyframes slidenavAnimation {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes slidenavAnimation {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    #fixed-menu #ca-lang-selector:hover ul.menu {
        display: block;
        padding: 10px 12px;
        background: rgba(45, 155, 174, 0.9);
    }
}

#fixed-menu.darkHeader #ca-lang-selector {
    color: #000 !important;
    background: transparent url(images/arrowBlack.svg) 28px 19px no-repeat;
    height: 60px;
    width: 50px;
    text-transform: uppercase;
    letter-spacing: 0;
    margin: 0 1px;
}

@media screen and (max-width: 768px) {
    #fixed-menu.darkHeader #ca-lang-selector {
        bottom: 0;
        right: 0;
        position: absolute;
    }
}

#fixed-menu.darkHeader #ca-lang-selector:hover {
    background: rgba(45, 155, 174, 0.9) url(images/arrowWhite.svg) 28px 36px no-repeat;
    color: #fff !important;
    animation-name: slidenavAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-name: slidenavAnimation;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: slidenavAnimation;
    -moz-animation-duration: 0.3s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

body.admin-menu #fixed-menu {
    top: 29px;
}

/* =============================
========== RESPONSIVE ==========
============================= */
@media (max-width: 1200px) {
    #fixed-menu #header-search form {
        width: 100%;
    }

    #fixed-menu #header-search form>div .selector .current {
        display: block;
    }

    #fixed-menu #header-search form>div .selector .menu {
        width: 200px;
        float: right;
    }

    #fixed-menu #header-search form>div .selector .menu li a {
        padding: 0 15px !important;
        text-align: left !important;
    }

    #fixed-menu #header-search form>div .selector .menu li.selected a {
        color: #007E8E;
    }

    #fixed-menu #header-search form>div .selector .menu li.selected a:hover {
        background: #F0F0F0;
    }

    .logged-in #fixed-menu.darkHeader #header-search .form-item-criteria-header {
        width: 246px !important;
    }
}

@media (max-width: 800px) {
    .not-logged-in #fixed-menu #header-search .form-item-criteria-header {
        width: 234px !important;
        -webkit-transition: width 0.2s;
        -moz-transition: width 0.2s;
        transition: width 0.2s;
    }

    .not-logged-in #fixed-menu.darkHeader #header-search .form-item-criteria-header {
        width: 178px !important;
        -webkit-transition: width 0.2s;
        -moz-transition: width 0.2s;
        transition: width 0.2s;
    }
}

@media screen and (max-width: 767px) {
    #fixed-menu #user-menu .user_info .user-menu .name {
        width: 35px;
        min-width: 35px;
        padding: 0 0 0 88px;
        text-indent: -1000px;
    }

    #fixed-menu #user-menu .user_info .user-menu ul.menu {
        left: auto;
    }
}

.page-user-register #main,
.page-user-welcome #main {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.page-user-welcome #main {
    padding-top: 0 !important;
}

/**CUSTOM */
a {
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
}

/*system.menus.css*/
/**
 * @file
 * Styles for menus and navigation markup.
 */

/**
 * Markup generated by theme_menu_tree().
 */
ul.menu {
    border: none;
    list-style: none;
    text-align: left;
    /* LTR */
}

ul.menu li {
    margin: 0 0 0 0.5em;
    /* LTR */
}

ul li.expanded {
    list-style-image: url(images/menu-expanded.png);
    list-style-type: circle;
}

ul li.collapsed {
    list-style-image: url(images/menu-collapsed.png);
    /* LTR */
    list-style-type: disc;
}

ul li.leaf {
    list-style-image: url(images/menu-leaf.png);
    list-style-type: square;
}

li.expanded,
li.collapsed,
li.leaf {
    padding: 0.2em 0.5em 0 0;
    /* LTR */
    margin: 0;
}

li a.active {
    color: #000;
}

td.menu-disabled {
    background: #ccc;
}

/**
 * Markup generated by theme_links().
 */
ul.inline,
ul.links.inline {
    display: inline;
    padding-left: 0;
}

ul.inline li {
    display: inline;
    list-style-type: none;
    padding: 0 0.5em;
}

/**
 * Markup generated by theme_breadcrumb().
 */
.breadcrumb {
    padding-bottom: 0.5em;
}

/**
 * Markup generated by theme_menu_local_tasks().
 */
ul.primary {
    border-bottom: 1px solid #bbb;
    border-collapse: collapse;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 5px;
    padding: 0 0 0 1em;
    /* LTR */
    white-space: nowrap;
}

ul.primary li {
    display: inline;
}

ul.primary li a {
    background-color: #ddd;
    border-color: #bbb;
    border-style: solid solid none solid;
    border-width: 1px;
    height: auto;
    margin-right: 0.5em;
    /* LTR */
    padding: 0 1em;
    text-decoration: none;
}

ul.primary li.active a {
    background-color: #fff;
    border: 1px solid #bbb;
    border-bottom: 1px solid #fff;
}

ul.primary li a:hover {
    background-color: #eee;
    border-color: #ccc;
    border-bottom-color: #eee;
}

ul.secondary {
    border-bottom: 1px solid #bbb;
    padding: 0.5em 1em;
    margin: 5px;
}

ul.secondary li {
    border-right: 1px solid #ccc;
    /* LTR */
    display: inline;
    padding: 0 1em;
}

ul.secondary a {
    padding: 0;
    text-decoration: none;

}

ul.secondary a.active {
    border-bottom: 4px solid #999;
}

#submenumobile .user-info {
    padding-top: 15px;
    padding-left: 25px;
    border-bottom: #FFF 1px solid;
    padding-bottom: 16px;
}

#submenumobile .user-info .user-picture {
    width: 40px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-align: center;
    background: #2E9DA8;
}

#submenumobile .user-info .user-picture img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}

#submenumobile .user-info .user-picture .no-picture {
    float: left;
    width: 40px;
    height: 40px;
    background: url(images/user_placeholder.svg) center center no-repeat;
    background-size: 44px 40px;
    background-position-x: -2px;
}

#submenumobile .user-info .user-name {
    margin-top: -40px;
    padding-left: 50px;
    line-height: 40px;
    white-space: nowrap;
}

#submenumobile .user-info .user-name a {
    color: #fff !important;
    text-transform: uppercase;
    cursor: pointer;
}
